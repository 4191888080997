
#id66a4e328914da0d427ff3590 {
  #ihuh {
padding : 10px; 
min-height : 100px; 
height : auto; 
display : flex; 
} 
#i1fk {
background-image : url('https://assetsprojects.s3.amazonaws.com/eewk3il9h8oh4b.png'); 
background-repeat : no-repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : cover; 
} 
#inlc {
padding : 10px; 
min-height : 100px; 
width : 30%; 
display : flex; 
justify-content : space-around; 
align-items : center; 
} 
#idpf {
padding : 10px; 
min-height : 0; 
width : 70%; 
display : flex; 
} 
#illj {
color : black; 
width : auto; 
max-width : 100%; 
height : 75px; 
align-items : center; 
} 
#i7cvw {
padding : 10px; 
min-height : 0; 
width : 25%; 
} 
#i2d1h {
padding : 10px; 
display : block; 
color : rgba(221, 227, 237, 1); 
text-align : center; 
} 
.link {
color : rgba(221, 227, 237, 1); 
text-align : left; 
} 
#ih8nn {
padding : 10px; 
display : block; 
color : rgba(221, 227, 237, 1); 
text-align : center; 
} 
#ihh3i {
padding : 10px; 
min-height : 0; 
width : 25%; 
} 
#izded {
padding : 10px; 
display : block; 
color : rgba(221, 227, 237, 1); 
text-align : center; 
} 
#i4d8t {
padding : 10px; 
min-height : 0; 
width : 25%; 
} 
#ihn67 {
padding : 10px; 
display : block; 
color : rgba(221, 227, 237, 1); 
text-align : center; 
} 
#i9ute {
padding : 10px; 
min-height : 0; 
width : 25%; 
} 
#iz8mc {
padding : 10px; 
min-height : 100vh; 
display : flex; 
} 
#irkrg {
padding : 10px; 
min-height : 100px; 
width : 50%; 
display : flex; 
flex-direction : column; 
} 
#i7rzq {
padding : 10px; 
min-height : 100px; 
width : 50%; 
display : flex; 
} 
#iq0wx {
color : black; 
width : auto; 
height : 400px; 
margin : 100px 0 0 0; 
} 
#icjaj {
padding : 10px; 
min-height : 0; 
display : flex; 
float : none; 
width : 100%; 
text-align : right; 
position : relative; 
justify-content : flex-end; 
margin : 100px 0 0 0; 
} 
#ibwb9 {
padding : 10px; 
min-height : 0; 
width : 60px; 
border : 0 solid black; 
display : flex; 
justify-content : center; 
flex-direction : column; 
} 
#irey9 {
padding : 10px; 
display : block; 
color : rgba(221, 227, 237, 1); 
font-size : 32px; 
font-weight : 600; 
width : 433.75px; 
} 
#il8ni {
padding : 0 10px 0 10px; 
min-height : 0; 
display : flex; 
float : none; 
width : 100%; 
text-align : right; 
position : relative; 
justify-content : flex-end; 
flex-direction : column; 
} 
#ibn48 {
padding : 0 10px 0 10px; 
display : block; 
color : rgba(221, 227, 237, 1); 
} 
#i7yrj {
padding : 10px; 
min-height : 100px; 
} 
.navbar-items-c {
display : inline-block; 
float : right; 
} 
.navbar {
color : rgb(221, 221, 221); 
min-height : 50px; 
width : 100%; 
} 
.navbar-container {
max-width : 950px; 
margin-top : 0px; 
margin-right : auto; 
margin-bottom : 0px; 
margin-left : auto; 
width : 95%; 
} 
.navbar-container::after  {
content : ""; 
clear : both; 
display : block; 
} 
.navbar-brand {
vertical-align : top; 
display : inline-block; 
padding-top : 5px; 
padding-right : 5px; 
padding-bottom : 5px; 
padding-left : 5px; 
min-height : 50px; 
min-width : 50px; 
color : inherit; 
text-decoration-line : none; 
text-decoration-thickness : initial; 
text-decoration-style : initial; 
text-decoration-color : initial; 
} 
.navbar-menu {
padding-top : 10px; 
padding-right : 0px; 
padding-bottom : 10px; 
padding-left : 0px; 
display : block; 
float : right; 
margin-top : 0px; 
margin-right : 0px; 
margin-bottom : 0px; 
margin-left : 0px; 
} 
.navbar-menu-link {
margin-top : 0px; 
margin-right : 0px; 
margin-bottom : 0px; 
margin-left : 0px; 
color : inherit; 
text-decoration-line : none; 
text-decoration-thickness : initial; 
text-decoration-style : initial; 
text-decoration-color : initial; 
display : inline-block; 
padding-top : 10px; 
padding-right : 15px; 
padding-bottom : 10px; 
padding-left : 15px; 
} 
.navbar-burger {
margin-top : 10px; 
margin-right : 0px; 
margin-bottom : 10px; 
margin-left : 0px; 
width : 45px; 
padding-top : 5px; 
padding-right : 10px; 
padding-bottom : 5px; 
padding-left : 10px; 
display : none; 
float : right; 
cursor : pointer; 
} 
.navbar-burger-line {
padding-top : 1px; 
padding-right : 1px; 
padding-bottom : 1px; 
padding-left : 1px; 
background-color : white; 
margin-top : 5px; 
margin-right : 0px; 
margin-bottom : 5px; 
margin-left : 0px; 
} 
.gjs-lofty-navbar-center-topnav {
position : relative; 
overflow-x : hidden; 
overflow-y : hidden; 
background-color : rgb(41, 42, 46); 
height : 150px; 
} 
.gjs-lofty-navbar-center-topnav p {
float : left; 
text-align : center; 
padding-top : 14px; 
padding-right : 16px; 
padding-bottom : 14px; 
padding-left : 16px; 
text-decoration-line : none; 
text-decoration-thickness : initial; 
text-decoration-style : initial; 
text-decoration-color : initial; 
} 
.gjs-lofty-navbar-center-topnav p.left {
color : white; 
} 
.gjs-lofty-navbar-center-topnav-centered-left {
float : none; 
position : absolute; 
top : 45%; 
transform : translate(-50%, -50%); 
font-family : Inika; 
font-size : 20px; 
} 
.gjs-lofty-navbar-center-topnav-centered-right {
float : none; 
position : absolute; 
top : 45%; 
transform : translate(-50%, -50%); 
font-family : Inika; 
font-size : 20px; 
} 
.gjs-lofty-navbar-center-topnav-right {
float : right; 
position : relative; 
top : 50%; 
font-size : 17px; 
} 
.gjs-lofty-navbar-center-topnav-image {
position : absolute; 
top : 10%; 
} 
#gjs-lofty-navbar-center-menu__toggle:checked + .menu__btn > span {
transform : rotate(45deg); 
left : 120px; 
top : -30px; 
} 
#gjs-lofty-navbar-center-menu__toggle:checked + .menu__btn > span::before {
top : 0px; 
transform : rotate(0deg); 
background-color : black; 
} 
#gjs-lofty-navbar-center-menu__toggle:checked + .menu__btn > span::after {
top : 0px; 
background-color : black; 
transform : rotate(90deg); 
} 
#gjs-lofty-navbar-center-menu__toggle:checked ~ .menu__box {
left : 0px !important; 
} 
.gjs-lofty-navbar-center-menu__btn {
position : absolute; 
top : 45%; 
left : 5%; 
width : 26px; 
height : 26px; 
cursor : pointer; 
z-index : 1; 
} 
.gjs-lofty-navbar-center-menu__btn > span, .gjs-lofty-navbar-center-menu__btn > span::before, .gjs-lofty-navbar-center-menu__btn > span::after {
display : block; 
position : absolute; 
width : 100%; 
height : 2px; 
background-color : rgb(214, 164, 25); 
transition-duration : 0.25s; 
} 
.gjs-lofty-navbar-center-menu__btn > span::before {
content : ""; 
top : -8px; 
} 
.gjs-lofty-navbar-center-menu__btn > span::after {
content : ""; 
top : 8px; 
} 
.gjs-lofty-navbar-center-menu__box {
display : block; 
position : fixed; 
top : 0px; 
left : -100%; 
width : 300px; 
height : 100%; 
margin-top : 0px; 
margin-right : 0px; 
margin-bottom : 0px; 
margin-left : 0px; 
padding-top : 80px; 
padding-right : 0px; 
padding-bottom : 80px; 
padding-left : 0px; 
list-style-position : initial; 
list-style-image : initial; 
list-style-type : none; 
background-color : rgb(214, 164, 25); 
box-shadow : rgba(0, 0, 0, 0.4) 2px 2px 6px; 
transition-duration : 0.25s; 
} 
.gjs-lofty-navbar-center-menu__item {
display : block; 
padding-top : 12px; 
padding-right : 24px; 
padding-bottom : 12px; 
padding-left : 24px; 
text-align : center; 
color : black; 
font-family : Roboto, sans-serif; 
font-size : 20px; 
font-weight : 600; 
text-decoration-line : none; 
text-decoration-thickness : initial; 
text-decoration-style : initial; 
text-decoration-color : initial; 
transition-duration : 0.25s; 
} 
.gjs-lofty-navbar-center-menu__item:hover  {
background-color : rgb(207, 216, 220); 
} 
.gjs-lofty-navbar-center-dropbtn {
background-color : rgb(41, 42, 46); 
color : white; 
padding-top : 12px; 
padding-right : 12px; 
padding-bottom : 12px; 
padding-left : 12px; 
font-size : 14px; 
border-top-width : initial; 
border-right-width : initial; 
border-bottom-width : initial; 
border-left-width : initial; 
border-top-style : none; 
border-right-style : none; 
border-bottom-style : none; 
border-left-style : none; 
border-top-color : initial; 
border-right-color : initial; 
border-bottom-color : initial; 
border-left-color : initial; 
border-image-source : initial; 
border-image-slice : initial; 
border-image-width : initial; 
border-image-outset : initial; 
border-image-repeat : initial; 
cursor : pointer; 
max-width : 100%; 
font-family : Arial, Helvetica, sans-serif; 
} 
.gjs-lofty-navbar-center-dropdown {
position : relative; 
top : 20%; 
} 
.gjs-lofty-navbar-center-dropdown-content {
display : none; 
position : absolute; 
background-color : gray; 
min-width : 60px; 
box-shadow : rgba(0, 0, 0, 0.2) 0px 8px 16px 0px; 
z-index : 1; 
} 
.gjs-lofty-navbar-center-dropdown-content a {
color : white; 
padding-top : 4px; 
padding-right : 6px; 
padding-bottom : 4px; 
padding-left : 6px; 
text-decoration-line : none; 
text-decoration-thickness : initial; 
text-decoration-style : initial; 
text-decoration-color : initial; 
display : block; 
font-family : Arial, Helvetica, sans-serif; 
} 
.gjs-lofty-navbar-center-dropdown-content a:hover {
background-color : rgb(241, 241, 241); 
} 
.gjs-lofty-navbar-center-dropdown:hover .dropdown-content {
display : block; 
} 
.gjs-lofty-navbar-center-dropdown:hover .dropbtn {
background-color : rgb(41, 42, 46); 
} 
#i9a0g {
padding : 0 0 0 0; 
min-height : 0; 
width : 50px; 
border : 2px solid rgba(240, 80, 80, 1); 
height : 0; 
} 
#ioyle {
padding : 10px; 
min-height : 100vh; 
display : flex; 
} 
#imw8i {
padding : 0 0 0 0; 
min-height : 0; 
width : 50px; 
border : 2px solid rgba(240, 80, 80, 1); 
height : 0; 
} 
#i0t65 {
padding : 10px; 
min-height : 0; 
width : 60px; 
border : 0 solid black; 
display : flex; 
justify-content : center; 
flex-direction : column; 
} 
#ii08k {
padding : 10px; 
display : block; 
color : rgba(221, 227, 237, 1); 
font-size : 32px; 
font-weight : 600; 
} 
#ir5tk {
padding : 10px; 
min-height : 0; 
display : flex; 
float : none; 
width : 100%; 
text-align : right; 
position : relative; 
justify-content : flex-end; 
margin : 150px 0 0 0; 
} 
#ico7l {
padding : 0 10px 0 10px; 
display : block; 
color : rgba(221, 227, 237, 1); 
font-size : 18px; 
letter-spacing : 5px; 
float : none; 
width : 50%; 
} 
#iokjz {
padding : 0 10px 0 10px; 
min-height : 0; 
display : flex; 
float : none; 
width : 100%; 
text-align : right; 
position : relative; 
justify-content : flex-end; 
flex-direction : row; 
} 
#i07jy {
padding : 10px; 
min-height : 100px; 
} 
#iee7l {
padding : 10px; 
min-height : 100px; 
width : 50%; 
display : flex; 
flex-direction : column; 
} 
#i77go {
padding : 10px; 
min-height : 100px; 
display : flex; 
flex-direction : column; 
align-items : flex-start; 
position : fixed; 
bottom : 50px; 
left : 10px; 
} 
#igokd {
padding : 10px; 
min-height : 100px; 
display : flex; 
width : 50%; 
flex-direction : column; 
} 
#iamm5 {
padding : 10px; 
min-height : 100px; 
display : flex; 
margin : 150px 0 10px 0; 
} 
#i48ku {
color : black; 
} 
#i87jx {
padding : 10px; 
display : block; 
color : rgba(221, 227, 237, 1); 
font-size : 17px; 
font-weight : 600; 
flex-direction : row; 
justify-content : flex-start; 
align-self : center; 
} 
#it7ji {
color : black; 
} 
#i3e3f {
padding : 10px; 
display : block; 
color : rgba(221, 227, 237, 1); 
font-size : 17px; 
font-weight : 600; 
flex-direction : row; 
justify-content : flex-start; 
align-self : center; 
} 
#ibyyv {
padding : 10px; 
min-height : 100px; 
display : flex; 
} 
#i7mg5 {
color : black; 
} 
#ihys9 {
padding : 10px; 
display : block; 
color : rgba(221, 227, 237, 1); 
font-size : 17px; 
font-weight : 600; 
flex-direction : row; 
justify-content : flex-start; 
align-self : center; 
} 
#ivm5v {
padding : 10px; 
min-height : 100px; 
display : flex; 
margin : 10px 0 10px 0; 
} 
#itkxb {
padding : 10px; 
min-height : 100vh; 
display : flex; 
flex-direction : column; 
} 
#igk42 {
padding : 10px; 
min-height : 100px; 
display : flex; 
} 
#i2k9f {
padding : 10px; 
min-height : 100px; 
width : 10%; 
} 
#ir7b67 {
padding : 0 0 0 0; 
min-height : 0; 
width : 50px; 
border : 2px solid rgba(240, 80, 80, 1); 
height : 0; 
} 
#i1giw1 {
padding : 10px; 
min-height : 0; 
width : 60px; 
border : 0 solid black; 
display : flex; 
justify-content : center; 
flex-direction : column; 
} 
#iqqtir {
padding : 10px; 
display : block; 
color : rgba(221, 227, 237, 1); 
font-size : 32px; 
font-weight : 700; 
} 
#i8yjmg {
padding : 10px; 
min-height : 0; 
display : flex; 
float : none; 
width : 100%; 
text-align : right; 
position : relative; 
justify-content : flex-start; 
} 
#iy0kzs {
padding : 10px; 
min-height : 100px; 
display : flex; 
} 
#igd98s {
padding : 10px; 
min-height : 100px; 
width : 10%; 
} 
#is2zfi {
padding : 10px; 
min-height : 100px; 
width : 10%; 
} 
.gjs-row {
display : flex; 
justify-content : flex-start; 
align-items : stretch; 
flex-wrap : wrap; 
padding-top : 10px; 
padding-right : 10px; 
padding-bottom : 10px; 
padding-left : 10px; 
} 
.gjs-cell {
min-height : 75px; 
width : 50%; 
} 
#i0r22k {
width : 80%; 
} 
#im9aj8 {
width : 24%; 
__bg-type : color; 
background-image : linear-gradient(rgba(69, 69, 69, 1),rgba(69, 69, 69, 1)); 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
display : flex; 
flex-direction : column; 
justify-content : center; 
padding : 10px 10px 10px 10px; 
margin : 0 10px 0 0; 
} 
#iqrg2c {
padding : 10px; 
min-height : 100px; 
display : flex; 
} 
#iybi2m {
padding : 10px; 
min-height : 100px; 
width : 10%; 
} 
#inrd62 {
padding : 10px; 
min-height : 100px; 
width : 80%; 
} 
#i93yy4 {
padding : 10px; 
min-height : 100px; 
width : 10%; 
} 
#iytiv9 {
padding : 10px; 
display : block; 
color : rgba(221, 227, 237, 1); 
font-size : 60px; 
text-align : center; 
font-weight : 700; 
} 
#iz6kkb {
padding : 10px; 
display : block; 
color : rgba(221, 227, 237, 1); 
font-size : 18px; 
text-align : center; 
font-weight : 400; 
} 
#iri6nk {
padding : 10px; 
min-height : 100px; 
width : 10%; 
} 
#i4e2g8 {
padding : 0 0 0 0; 
min-height : 0; 
width : 50px; 
border : 2px solid rgba(240, 80, 80, 1); 
height : 0; 
} 
#ibsh6e {
padding : 10px; 
min-height : 0; 
width : 60px; 
border : 0 solid black; 
display : flex; 
justify-content : center; 
flex-direction : column; 
} 
#ilpn6s {
padding : 10px; 
display : block; 
color : rgba(221, 227, 237, 1); 
font-size : 32px; 
font-weight : 700; 
} 
#is9fbi {
padding : 10px; 
min-height : 0; 
display : flex; 
float : none; 
width : 100%; 
text-align : right; 
position : relative; 
justify-content : flex-start; 
} 
#i7v3nj {
padding : 10px; 
min-height : 100px; 
display : flex; 
} 
#ixu4ka {
padding : 10px; 
min-height : 100px; 
width : 13%; 
} 
#itnnmf {
width : 25%; 
display : flex; 
flex-direction : column; 
justify-content : flex-end; 
padding : 10px 10px 10px 10px; 
margin : 0 0 0 0; 
height : auto; 
align-items : flex-start; 
} 
#il84yx {
width : 24%; 
display : flex; 
flex-direction : column; 
justify-content : flex-start; 
padding : 10px 0 0 0; 
margin : 0 10px 0 0; 
align-self : auto; 
align-items : flex-start; 
border : 1px solid rgba(221, 227, 237, 1); 
} 
#i95cyf {
width : 80%; 
} 
#i68ult {
padding : 10px; 
min-height : 100px; 
width : 13%; 
} 
#i13hzg {
padding : 10px; 
min-height : 100px; 
display : flex; 
} 
#if7cvl {
padding : 10px; 
min-height : 100vh; 
display : flex; 
flex-direction : column; 
} 
#i5v0ag {
padding : 10px 0 10px 10px; 
min-height : 100px; 
width : 100%; 
display : flex; 
justify-content : flex-end; 
} 
#iwsjnr {
padding : 10px; 
min-height : 100px; 
width : 100%; 
__bg-type : color; 
background-image : linear-gradient(rgba(63, 63, 63, 1),rgba(63, 63, 63, 1)); 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
display : flex; 
justify-content : center; 
align-self : auto; 
} 
#iwil1l {
color : black; 
height : auto; 
} 
#i3r2qq {
padding : 10px; 
display : block; 
color : rgba(221, 227, 237, 1); 
font-size : 17px; 
font-weight : 700; 
align-self : center; 
} 
#ihijoh {
color : black; 
height : auto; 
} 
#i1aejp {
padding : 10px 0 10px 10px; 
min-height : 100px; 
width : 100%; 
display : flex; 
justify-content : flex-end; 
} 
#iql052 {
padding : 10px; 
display : block; 
color : rgba(221, 227, 237, 1); 
font-size : 17px; 
font-weight : 700; 
align-self : center; 
} 
#i5p3xm {
padding : 10px; 
min-height : 100px; 
width : 100%; 
__bg-type : color; 
background-image : linear-gradient(rgba(63, 63, 63, 1),rgba(63, 63, 63, 1)); 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
display : flex; 
justify-content : center; 
align-self : auto; 
} 
#iydl7f {
width : 24%; 
display : flex; 
flex-direction : column; 
justify-content : flex-start; 
padding : 10px 0 0 0; 
margin : 0 10px 0 0; 
align-self : auto; 
align-items : flex-start; 
border : 1px solid rgba(221, 227, 237, 1); 
} 
#ij0n11 {
color : black; 
height : auto; 
} 
#iz8cko {
padding : 10px 0 10px 10px; 
min-height : 100px; 
width : 100%; 
display : flex; 
justify-content : flex-end; 
} 
#ijbl6m {
padding : 10px; 
display : block; 
color : rgba(221, 227, 237, 1); 
font-size : 17px; 
font-weight : 700; 
align-self : center; 
} 
#ivn2qp {
padding : 10px; 
min-height : 100px; 
width : 100%; 
__bg-type : color; 
background-image : linear-gradient(rgba(63, 63, 63, 1),rgba(63, 63, 63, 1)); 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
display : flex; 
justify-content : center; 
align-self : auto; 
} 
#i2815g {
width : 24%; 
display : flex; 
flex-direction : column; 
justify-content : flex-start; 
padding : 10px 0 0 0; 
margin : 0 10px 0 0; 
align-self : auto; 
align-items : flex-start; 
border : 1px solid rgba(221, 227, 237, 1); 
} 
#ifpf28 {
padding : 10px; 
min-height : 100px; 
width : 100%; 
} 
#im9bd5 {
padding : 10px; 
display : block; 
color : rgba(221, 227, 237, 1); 
} 
#ixmaj4 {
padding : 10px; 
min-height : 100px; 
width : 10%; 
} 
#i98nvj {
padding : 0 0 0 0; 
min-height : 0; 
width : 50px; 
border : 2px solid rgba(240, 80, 80, 1); 
height : 0; 
} 
#i4569h {
padding : 10px; 
min-height : 0; 
width : 60px; 
border : 0 solid black; 
display : flex; 
justify-content : center; 
flex-direction : column; 
} 
#iwrrfj {
padding : 10px; 
display : block; 
color : rgba(221, 227, 237, 1); 
font-size : 32px; 
font-weight : 700; 
} 
#i7vpvm {
padding : 10px; 
min-height : 0; 
display : flex; 
float : none; 
width : 100%; 
text-align : right; 
position : relative; 
justify-content : flex-start; 
} 
#ile7op {
padding : 10px; 
min-height : 100px; 
display : flex; 
} 
#imey7v {
padding : 10px; 
min-height : 100px; 
width : 50%; 
display : flex; 
flex-direction : row; 
justify-content : center; 
align-items : center; 
align-self : auto; 
} 
#id6zqn {
padding : 10px; 
min-height : 100px; 
width : 50%; 
justify-content : space-around; 
align-items : center; 
display : flex; 
flex-direction : column; 
} 
#iz6bji {
padding : 10px; 
min-height : 100px; 
display : flex; 
} 
#im8uk6 {
padding : 10px; 
min-height : 100px; 
display : flex; 
flex-direction : column; 
} 
#is5k2g {
padding : 10px; 
min-height : 100px; 
width : 80%; 
justify-content : center; 
align-items : center; 
display : flex; 
flex-direction : row; 
__bg-type : color; 
background-image : linear-gradient(rgba(29, 29, 29, 1),rgba(29, 29, 29, 1)); 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
box-shadow : 0 0 5px 0 gray; 
} 
#iqkjvg {
padding : 10px; 
display : block; 
color : rgba(221, 227, 237, 1); 
align-self : auto; 
margin : 10px 10px 10px 10px; 
} 
#iaovx1 {
padding : 10px; 
min-height : 100px; 
display : flex; 
width : 80%; 
flex-direction : column; 
justify-content : flex-start; 
align-items : flex-start; 
align-self : flex-end; 
} 
#is6mpj {
padding : 10px; 
display : block; 
color : rgba(221, 227, 237, 1); 
align-self : auto; 
margin : 10px 10px 10px 10px; 
} 
#i8aw0j {
padding : 10px; 
display : block; 
color : rgba(221, 227, 237, 1); 
align-self : auto; 
margin : 10px 10px 10px 10px; 
font-weight : 700; 
font-size : 17px; 
} 
#il35yc {
padding : 10px; 
display : block; 
color : rgba(221, 227, 237, 1); 
align-self : auto; 
margin : 10px 10px 10px 10px; 
font-weight : 700; 
} 
#ixvud7 {
padding : 10px; 
display : block; 
color : rgba(221, 227, 237, 1); 
align-self : auto; 
margin : 10px 10px 10px 10px; 
} 
#i2x09m {
color : black; 
margin : 15px 15px 15px 15px; 
height : 25px; 
} 
#is3wb5 {
color : black; 
margin : 15px 15px 15px 15px; 
width : auto; 
height : 25px; 
} 
#imxd3m {
color : black; 
margin : 15px 15px 15px 15px; 
height : 25px; 
} 
#ilkxzh {
padding : 25px 100px 25px 100px; 
min-height : 100px; 
__bg-type : color; 
background-image : linear-gradient(rgba(12, 12, 12, 0.9),rgba(12, 12, 12, 0.9)); 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
text-align : left; 
} 
#ikgcxg {
padding : 10px; 
display : block; 
color : rgba(221, 227, 237, 1); 
font-weight : 300; 
} 
#i3k8 {
background-image : url('https://assetsprojects.s3.amazonaws.com/eewk3il9h8nvkw.png'); 
background-repeat : no-repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : cover; 
} 
#iwtk {
background-image : url('https://assetsprojects.s3.amazonaws.com/eewk3il9h8oh4b.png'); 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
} 
#i6h9 {
background-image : url('https://assetsprojects.s3.amazonaws.com/eewk3il9h8nvkw.png'); 
background-repeat : no-repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : cover; 
} 
#if9wqu {
padding : 10px; 
min-height : 100px; 
__bg-type : img; 
background-image : url('https://assetsprojects.s3.amazonaws.com/eewk3il9h8nvkw.png'); 
background-repeat : no-repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : cover; 
} 
@media only screen and (max-width: 992px) {#ihuh {
flex-direction : column; 
}} 
@media only screen and (max-width: 992px) {#inlc {
width : 100%; 
}} 
@media only screen and (max-width: 992px) {#idpf {
width : 100%; 
}} 
@media only screen and (max-width: 992px) {#irey9 {
font-size : 20px; 
}} 
@media only screen and (max-width: 992px) {#iq0wx {
height : 300px; 
}} 
@media only screen and (max-width: 992px) {#iz8mc {
min-height : 600px; 
}} 
@media only screen and (max-width: 992px) {#ico7l {
font-size : 14px; 
width : 75%; 
}} 
@media only screen and (max-width: 992px) {#ii08k {
font-size : 21px; 
}} 
@media only screen and (max-width: 992px) {#i87jx {
font-size : 14px; 
}} 
@media only screen and (max-width: 992px) {#i48ku {
height : 80px; 
}} 
@media only screen and (max-width: 992px) {#ihys9 {
font-size : 14px; 
}} 
@media only screen and (max-width: 992px) {#i7mg5 {
height : 80px; 
}} 
@media only screen and (max-width: 992px) {#i3e3f {
font-size : 14px; 
}} 
@media only screen and (max-width: 992px) {#it7ji {
height : 80px; 
}} 
@media only screen and (max-width: 992px) {#im9aj8 {
width : 48%; 
height : 200px; 
}} 
@media only screen and (max-width: 992px) {#iytiv9 {
font-size : 32px; 
}} 
@media only screen and (max-width: 992px) {#iz6kkb {
font-size : 14px; 
}} 
@media only screen and (max-width: 992px) {#ilpn6s {
font-size : 32px; 
}} 
@media only screen and (max-width: 992px) {#itnnmf {
width : 47%; 
margin : 0 10px 0 0; 
}} 
@media only screen and (max-width: 992px) {#il84yx {
width : 47%; 
}} 
@media only screen and (max-width: 992px) {#i2815g {
width : 47%; 
margin : 10px 10px 0 0; 
}} 
@media only screen and (max-width: 992px) {#iydl7f {
width : 47%; 
margin : 10px 10px 0 0; 
}} 
@media only screen and (max-width: 992px) {#ioyle {
min-height : 700px; 
}} 
@media only screen and (max-width: 992px) {#iqkjvg {
font-size : 12px; 
}} 
@media only screen and (max-width: 992px) {#i8aw0j {
font-size : 14px; 
}} 
@media only screen and (max-width: 992px) {#is6mpj {
font-size : 12px; 
}} 
@media only screen and (max-width: 992px) {#il35yc {
font-size : 14px; 
}} 
@media only screen and (max-width: 992px) {#ixvud7 {
font-size : 12px; 
}} 
#izrqjl {
padding : 10px; 
min-height : 100px; 
display : none; 
} 
@media only screen and (max-width: 480px) {#izrqjl {
display : block; 
}} 
@media only screen and (max-width: 480px) {#idpf {
display : none; 
}} 
@media only screen and (max-width: 480px) {#ihuh {
flex-direction : row; 
}} 
@media only screen and (max-width: 480px) {#iz8mc {
flex-direction : column; 
min-height : 600px; 
}} 
@media only screen and (max-width: 480px) {#irkrg {
width : 100%; 
align-items : center; 
justify-content : flex-start; 
align-self : auto; 
flex-direction : column; 
}} 
@media only screen and (max-width: 480px) {#i7rzq {
width : 100%; 
flex-direction : row; 
justify-content : flex-start; 
align-items : flex-start; 
align-self : auto; 
min-height : auto; 
padding : 0 10px 0 10px; 
display : block; 
}} 
@media only screen and (max-width: 480px) {#iq0wx {
width : auto; 
height : auto; 
justify-content : center; 
align-items : center; 
flex-direction : column; 
align-self : auto; 
text-align : left; 
display : flex; 
margin : 10px 0 0 0; 
}} 
@media only screen and (max-width: 480px) {#i7yrj {
display : none; 
}} 
@media only screen and (max-width: 480px) {#i77go {
flex-direction : row; 
width : 100%; 
justify-content : space-around; 
right : 0; 
left : 0; 
}} 
@media only screen and (max-width: 480px) {#ibn48 {
text-align : center; 
}} 
@media only screen and (max-width: 480px) {#ioyle {
flex-direction : column; 
}} 
@media only screen and (max-width: 480px) {#iee7l {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#igokd {
width : 100%; 
margin : 0 0 0 0; 
}} 
@media only screen and (max-width: 480px) {#iamm5 {
margin : 10px 0 10px 0; 
}} 
@media only screen and (max-width: 480px) {#ir5tk {
margin : 10px 0 0 0; 
justify-content : center; 
}} 
@media only screen and (max-width: 480px) {#icjaj {
margin : 15px 0 0 0; 
justify-content : center; 
}} 
@media only screen and (max-width: 480px) {#i2k9f {
display : none; 
}} 
@media only screen and (max-width: 480px) {#iqqtir {
font-size : 17px; 
text-align : left; 
float : none; 
line-height : 50px; 
}} 
@media only screen and (max-width: 480px) {#i8yjmg {
justify-content : center; 
}} 
@media only screen and (max-width: 480px) {#im9aj8 {
width : 100%; 
margin : 0 0 0 0; 
}} 
@media only screen and (max-width: 480px) {#igd98s {
display : none; 
}} 
@media only screen and (max-width: 480px) {#is2zfi {
display : none; 
}} 
@media only screen and (max-width: 480px) {#i0r22k {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#iybi2m {
display : none; 
}} 
@media only screen and (max-width: 480px) {#i93yy4 {
display : none; 
}} 
@media only screen and (max-width: 480px) {#inrd62 {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#iytiv9 {
font-size : 20px; 
}} 
@media only screen and (max-width: 480px) {#iz6kkb {
font-size : 12px; 
}} 
@media only screen and (max-width: 480px) {#iri6nk {
display : none; 
}} 
@media only screen and (max-width: 480px) {#ilpn6s {
font-size : 14px; 
text-align : center; 
}} 
@media only screen and (max-width: 480px) {#ixu4ka {
display : none; 
}} 
@media only screen and (max-width: 480px) {#i68ult {
display : none; 
}} 
@media only screen and (max-width: 480px) {#i95cyf {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#itnnmf {
width : 100%; 
margin : 0 0 0 0; 
}} 
@media only screen and (max-width: 480px) {#il84yx {
margin : 10px 0 0 0; 
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#i2815g {
margin : 10px 0 0 0; 
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#iydl7f {
width : 100%; 
margin : 10px 0 0 0; 
min-height : auto; 
}} 
@media only screen and (max-width: 480px) {#iz6bji {
flex-direction : column; 
}} 
@media only screen and (max-width: 480px) {#imey7v {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#ixmaj4 {
display : none; 
}} 
@media only screen and (max-width: 480px) {#iwrrfj {
font-size : 14px; 
line-height : 50px; 
}} 
@media only screen and (max-width: 480px) {#i7vpvm {
justify-content : center; 
}} 
@media only screen and (max-width: 480px) {#is9fbi {
justify-content : center; 
}} 
@media only screen and (max-width: 480px) {#id6zqn {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#ilkxzh {
background-image : linear-gradient(rgba(0, 0, 0, 0.9),rgba(0, 0, 0, 0.9)); 
__bg-type : color; 
padding : 25px 10px 25px 10px; 
}} 
@media only screen and (max-width: 480px) {#ii08k {
font-size : 14px; 
}} 
@media only screen and (max-width: 480px) {#iokjz {
justify-content : center; 
}} 
@media only screen and (max-width: 480px) {#ico7l {
font-size : 12px; 
text-align : center; 
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#i07jy {
display : none; 
}} 
@media only screen and (max-width: 480px) {#irey9 {
text-align : center; 
font-size : 14px; 
}} 
@media only screen and (max-width: 480px) {#iqkjvg {
text-align : center; 
margin : 0 10px 10px 10px; 
}} 
@media only screen and (max-width: 480px) {#is6mpj {
text-align : center; 
}} 
@media only screen and (max-width: 480px) {#ixvud7 {
text-align : center; 
}} 
@media only screen and (max-width: 480px) {#iaovx1 {
justify-content : center; 
align-items : center; 
}} 
.formContainer {
width : 100%; 
padding-top : 5px; 
padding-right : 5px; 
padding-bottom : 5px; 
padding-left : 5px; 
} 
.componentInput {
padding-top : 5px; 
padding-right : 5px; 
padding-bottom : 5px; 
padding-left : 5px; 
margin-bottom : 10px; 
background-image : initial; 
background-position-x : initial; 
background-position-y : initial; 
background-size : initial; 
background-repeat-x : initial; 
background-repeat-y : initial; 
background-attachment : initial; 
background-origin : initial; 
background-clip : initial; 
background-color : transparent; 
border-top-width : initial; 
border-right-width : initial; 
border-left-width : initial; 
border-top-style : none; 
border-right-style : none; 
border-left-style : none; 
border-top-color : initial; 
border-right-color : initial; 
border-left-color : initial; 
border-image-source : initial; 
border-image-slice : initial; 
border-image-width : initial; 
border-image-outset : initial; 
border-image-repeat : initial; 
border-bottom-width : 1px; 
border-bottom-style : solid; 
border-bottom-color : rgb(0, 0, 0); 
width : 100%; 
} 
.title {
display : none; 
align-items : center; 
margin-bottom : 20px; 
} 
.fieldsContainer {
display : flex; 
flex-direction : column; 
padding-top : 20px; 
padding-right : 20px; 
padding-bottom : 20px; 
padding-left : 20px; 
justify-content : center; 
align-items : center; 
} 
.formSubmitButton {
text-align : center; 
text-decoration-line : none; 
text-decoration-thickness : initial; 
text-decoration-style : initial; 
text-decoration-color : initial; 
display : inline-block; 
background-image : linear-gradient(#212121 0%, #212121 100%); 
border-top-width : 1px; 
border-right-width : 1px; 
border-bottom-width : 1px; 
border-left-width : 1px; 
border-top-style : solid; 
border-right-style : solid; 
border-bottom-style : solid; 
border-left-style : solid; 
border-top-color : rgb(0, 119, 204); 
border-right-color : rgb(0, 119, 204); 
border-bottom-color : rgb(0, 119, 204); 
border-left-color : rgb(0, 119, 204); 
border-image-source : initial; 
border-image-slice : initial; 
border-image-width : initial; 
border-image-outset : initial; 
border-image-repeat : initial; 
font-size : 14px; 
margin-top : 4px; 
margin-right : 2px; 
margin-bottom : 4px; 
margin-left : 2px; 
cursor : pointer; 
color : rgb(255, 255, 255); 
__background-type : color; 
box-shadow : 1px -1px 3px 0px rgba(50,50,50,0.9) ; 
padding : 10px 10px 10px 10px; 
width : 200px; 
font-weight : 700; 
font-family : Helvetica, sans-serif; 
border-radius : 0px 0px 0px 0px; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
border : 0 none black; 
} 
.formSubmitButton:disabled  {
background-color : rgb(226, 229, 222); 
color : gray; 
} 
.formSubmitButton:hover  {
background-image : linear-gradient(rgb(81, 169, 238), rgb(20, 123, 205)); 
border-top-color : rgb(20, 130, 208); 
border-right-color : rgb(20, 130, 208); 
border-bottom-color : rgb(20, 130, 208); 
border-left-color : rgb(20, 130, 208); 
color : rgb(255, 255, 255); 
} 
.formSubmitButton:active  {
background-image : radial-gradient(circle at 24.1% 68.8%, rgb(50, 50, 50) 0%, rgb(0, 0, 0) 99.4%); 
border-top-color : rgb(0, 109, 188); 
border-right-color : rgb(0, 109, 188); 
border-bottom-color : rgb(0, 109, 188); 
border-left-color : rgb(0, 109, 188); 
color : rgb(255, 255, 255); 
} 
.inputContainer {
width : 100px; 
padding-top : 5px; 
padding-right : 5px; 
padding-bottom : 5px; 
padding-left : 5px; 
} 
.formInput {
padding-top : 5px; 
padding-right : 5px; 
padding-bottom : 5px; 
padding-left : 5px; 
margin-bottom : 10px; 
background-image : initial; 
background-position-x : initial; 
background-position-y : initial; 
background-size : initial; 
background-repeat-x : initial; 
background-repeat-y : initial; 
background-attachment : initial; 
background-origin : initial; 
background-clip : initial; 
background-color : transparent; 
border-top-width : initial; 
border-right-width : initial; 
border-left-width : initial; 
border-top-style : none; 
border-right-style : none; 
border-left-style : none; 
border-top-color : initial; 
border-right-color : initial; 
border-left-color : initial; 
border-image-source : initial; 
border-image-slice : initial; 
border-image-width : initial; 
border-image-outset : initial; 
border-image-repeat : initial; 
border-bottom-width : 1px; 
border-bottom-style : solid; 
border-bottom-color : rgb(0, 0, 0); 
width : 100%; 
} 
#iaeliw {
padding : 5px 10px 2px 10px; 
__background-type : grad; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(to right, rgba(151, 151, 151, 255) 62%, white 97%); 
border-radius : 15px 15px 15px 15px; 
} 
#iuknkx {
padding : 5px 10px 2px 10px; 
__background-type : grad; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(to right, rgba(151, 151, 151, 255) 62%, white 97%); 
border-radius : 15px 15px 15px 15px; 
} 
#ie2pxa {
padding : 5px 10px 2px 10px; 
__background-type : grad; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(to right, rgba(151, 151, 151, 255) 62%, white 97%); 
border-radius : 15px 15px 15px 15px; 
} 
#ilgeip {
padding : 5px 10px 2px 10px; 
__background-type : grad; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(to right, rgba(151, 151, 151, 255) 62%, white 97%); 
border-radius : 15px 15px 15px 15px; 
} 
.crudContainerDB {
width : 100%; 
padding-top : 5px; 
padding-right : 5px; 
padding-bottom : 5px; 
padding-left : 5px; 
} 
#iuu374 {
min-height : 100px; 
padding : 10px; 
width : 100%; 
} 
#ittqxg {
padding : 10px; 
display : flex; 
flex-direction : column; 
justify-content : center; 
align-items : center; 
} 
#igy239 {
padding : 5px; 
margin-top : 5px; 
margin-bottom : 5px; 
width : 100%; 
} 
#in7yge {
background-color : transparent; 
width : 100%; 
color : #dde3ed; 
} 
#iss7og {
padding : 5px; 
margin-top : 5px; 
margin-bottom : 5px; 
width : 100%; 
} 
#i19iif {
background-color : transparent; 
width : 100%; 
color : #dde3ed; 
} 
#is643o {
padding : 5px; 
margin-top : 5px; 
margin-bottom : 5px; 
width : 100%; 
} 
#ity6ca {
background-color : transparent; 
width : 100%; 
color : #dde3ed; 
} 
#ihvc7j {
padding : 5px; 
margin-top : 5px; 
margin-bottom : 5px; 
width : 100%; 
} 
#ik91rd {
background-color : transparent; 
font-size : 18px; 
width : 100%; 
color : #dde3ed; 
} 
#iet9pr {
font-size : 18px; 
color : #dde3ed; 
font-weight : 300; 
} 
#idvkmj {
color : #dde3ed; 
font-size : 18px; 
font-weight : 300; 
} 
#ioozel {
color : #dde3ed; 
font-size : 18px; 
font-weight : 300; 
} 
#iq375g {
color : #dde3ed; 
font-weight : 300; 
font-size : 18px; 
} 
#i58n9p {
width : 200px; 
padding : 10px 10px 10px 10px; 
color : #ffffff; 
font-family : Helvetica, sans-serif; 
font-size : 14px; 
font-weight : 700; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#212121 0%, #212121 100%); 
box-shadow : 1px -1px 3px 0 rgba(50,50,50,0.9) ; 
} 
.gjs-container {
padding-top : 10px; 
padding-right : 10px; 
padding-bottom : 10px; 
padding-left : 10px; 
min-height : 100px; 
display : block; 
} 
#inlxjs {
min-height : 100px; 
display : block; 
} 
#i34ziv {
min-height : 100px; 
display : block; 
} 
#iv6wxw {
min-height : 100px; 
display : block; 
} 
#id21rj {
min-height : 100px; 
display : block; 
} 
.titulo {
color : #ffffff; 
font-size : 1.2rem; 
font-weight : 600; 
text-align : center; 
} 
.descripcion {
color : #ffffff; 
text-align : left; 
} 
#ixpmmi {
padding : 10px; 
display : block; 
} 
#ieve6s {
padding : 10px; 
min-height : 100px; 
} 
#iq6w0u {
padding : 10px; 
display : block; 
} 
#i0iq35 {
padding : 10px; 
min-height : 100px; 
} 
#iz8kkg {
padding : 10px; 
display : block; 
} 
#i0ny5i {
padding : 10px; 
min-height : 100px; 
} 
#i6y3mj {
padding : 10px; 
display : block; 
} 
#iazuqc {
padding : 10px; 
min-height : 100px; 
} 
#izacmz {
width : 24%; 
__bg-type : color; 
background-image : linear-gradient(rgba(69, 69, 69, 1),rgba(69, 69, 69, 1)); 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
display : flex; 
flex-direction : column; 
justify-content : center; 
padding : 10px 10px 10px 10px; 
margin : 0 10px 0 0; 
} 
@media only screen and (max-width: 992px) {#izacmz {
width : 48%; 
height : 200px; 
}} 
@media only screen and (max-width: 480px) {#izacmz {
width : 100%; 
margin : 0 0 0 0; 
}} 
#icpg6x {
padding : 10px; 
display : block; 
} 
#iih9g6 {
padding : 10px; 
min-height : 100px; 
} 
#irckav {
padding : 10px; 
display : block; 
} 
#ikyklj {
padding : 10px; 
min-height : 100px; 
} 
#i79oau {
width : 24%; 
__bg-type : color; 
background-image : linear-gradient(rgba(69, 69, 69, 1),rgba(69, 69, 69, 1)); 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
display : flex; 
flex-direction : column; 
justify-content : center; 
padding : 10px 10px 10px 10px; 
margin : 0 10px 0 0; 
} 
@media only screen and (max-width: 992px) {#i79oau {
width : 48%; 
height : 200px; 
}} 
@media only screen and (max-width: 480px) {#i79oau {
width : 100%; 
margin : 0 0 0 0; 
}} 
#irdrs2 {
padding : 10px; 
display : block; 
} 
#in31bh {
padding : 10px; 
min-height : 100px; 
} 
#ibjm4s {
padding : 10px; 
display : block; 
} 
#i8cii2 {
padding : 10px; 
min-height : 100px; 
} 
#inhjxp {
width : 24%; 
__bg-type : color; 
background-image : linear-gradient(rgba(69, 69, 69, 1),rgba(69, 69, 69, 1)); 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
display : flex; 
flex-direction : column; 
justify-content : center; 
padding : 10px 10px 10px 10px; 
margin : 0 10px 0 0; 
} 
@media only screen and (max-width: 992px) {#inhjxp {
width : 48%; 
height : 200px; 
}} 
@media only screen and (max-width: 480px) {#inhjxp {
width : 100%; 
margin : 0 0 0 0; 
}} 
#i2d1h:hover  {
color : #f05050; 
} 
#izded:hover  {
color : #f05050; 
} 
#ihn67:hover  {
color : #f05050; 
} 
#ih8nn:hover  {
color : #f05050; 
} 

  @media only screen and (max-width: 992px) {#is5k2g {
width : 100%; 
}} 
@media only screen and (max-width: 992px) {#iaeliw {
padding : 3px 10px 2px 10px; 
}} 
@media only screen and (max-width: 992px) {#iuknkx {
padding : 3px 10px 2px 10px; 
}} 
@media only screen and (max-width: 992px) {#ie2pxa {
padding : 3px 10px 2px 10px; 
}} 
@media only screen and (max-width: 992px) {#ilgeip {
padding : 3px 10px 2px 10px; 
}} 
@media only screen and (max-width: 992px) {#im9aj8 {
height : auto; 
margin : 0px 10px 10px 0px; 
}} 
@media only screen and (max-width: 992px) {#inhjxp {
height : auto; 
margin : 0px 10px 10px 0px; 
}} 
@media only screen and (max-width: 992px) {#i79oau {
height : auto; 
}} 
@media only screen and (max-width: 992px) {#izacmz {
height : auto; 
}} 

  @media only screen and (max-width: 480px) {#ilgeip {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#i58n9p {
font-size : 12px; 
width : 150px; 
}} 
@media only screen and (max-width: 480px) {#idvkmj {
text-align : left; 
}} 
@media only screen and (max-width: 480px) {#iss7og {
display : flex; 
flex-direction : column; 
justify-content : flex-start; 
align-items : center; 
}} 
@media only screen and (max-width: 480px) {#iuknkx {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#iaeliw {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#ie2pxa {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#igy239 {
display : flex; 
flex-direction : column; 
align-items : center; 
}} 
@media only screen and (max-width: 480px) {#is643o {
display : flex; 
flex-direction : column; 
align-items : center; 
}} 
@media only screen and (max-width: 480px) {#ihvc7j {
display : flex; 
flex-direction : column; 
justify-content : center; 
align-items : center; 
}} 
@media only screen and (max-width: 480px) {#i77go {
display : none; 
}} 

}
  