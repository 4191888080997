
#id6353093593bf11cc1baddfce {
  #ihuh {
padding : 10px; 
min-height : 100px; 
height : auto; 
display : flex; 
} 
#i1fk {
background-image : url('https://assetsprojects.s3.amazonaws.com/eewk3il9h8oh4b.png'); 
background-repeat : no-repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : cover; 
} 
#inlc {
padding : 10px; 
min-height : 100px; 
width : 30%; 
display : flex; 
justify-content : space-around; 
align-items : center; 
} 
#idpf {
padding : 10px; 
min-height : 0; 
width : 70%; 
display : flex; 
} 
#illj {
color : black; 
width : auto; 
max-width : 100%; 
height : 75px; 
align-items : center; 
} 
#i7cvw {
padding : 10px; 
min-height : 0; 
width : 25%; 
} 
#i2d1h {
padding : 10px; 
display : block; 
color : rgba(221, 227, 237, 1); 
text-align : center; 
} 
.link {
color : rgba(221, 227, 237, 1); 
text-align : left; 
} 
#ih8nn {
padding : 10px; 
display : block; 
color : rgba(221, 227, 237, 1); 
text-align : center; 
} 
#ihh3i {
padding : 10px; 
min-height : 0; 
width : 25%; 
} 
#izded {
padding : 10px; 
display : block; 
color : rgba(221, 227, 237, 1); 
text-align : center; 
} 
#i4d8t {
padding : 10px; 
min-height : 0; 
width : 25%; 
} 
#ihn67 {
padding : 10px; 
display : block; 
color : rgba(221, 227, 237, 1); 
text-align : center; 
} 
#i9ute {
padding : 10px; 
min-height : 0; 
width : 25%; 
} 
.navbar-items-c {
display : inline-block; 
float : right; 
} 
.navbar {
color : rgb(221, 221, 221); 
min-height : 50px; 
width : 100%; 
} 
.navbar-container {
max-width : 950px; 
margin-top : 0px; 
margin-right : auto; 
margin-bottom : 0px; 
margin-left : auto; 
width : 95%; 
} 
.navbar-container::after  {
content : ""; 
clear : both; 
display : block; 
} 
.navbar-brand {
vertical-align : top; 
display : inline-block; 
padding-top : 5px; 
padding-right : 5px; 
padding-bottom : 5px; 
padding-left : 5px; 
min-height : 50px; 
min-width : 50px; 
color : inherit; 
text-decoration-line : none; 
text-decoration-thickness : initial; 
text-decoration-style : initial; 
text-decoration-color : initial; 
} 
.navbar-menu {
padding-top : 10px; 
padding-right : 0px; 
padding-bottom : 10px; 
padding-left : 0px; 
display : block; 
float : right; 
margin-top : 0px; 
margin-right : 0px; 
margin-bottom : 0px; 
margin-left : 0px; 
} 
.navbar-menu-link {
margin-top : 0px; 
margin-right : 0px; 
margin-bottom : 0px; 
margin-left : 0px; 
color : inherit; 
text-decoration-line : none; 
text-decoration-thickness : initial; 
text-decoration-style : initial; 
text-decoration-color : initial; 
display : inline-block; 
padding-top : 10px; 
padding-right : 15px; 
padding-bottom : 10px; 
padding-left : 15px; 
} 
.navbar-burger {
margin-top : 10px; 
margin-right : 0px; 
margin-bottom : 10px; 
margin-left : 0px; 
width : 45px; 
padding-top : 5px; 
padding-right : 10px; 
padding-bottom : 5px; 
padding-left : 10px; 
display : none; 
float : right; 
cursor : pointer; 
} 
.navbar-burger-line {
padding-top : 1px; 
padding-right : 1px; 
padding-bottom : 1px; 
padding-left : 1px; 
background-color : white; 
margin-top : 5px; 
margin-right : 0px; 
margin-bottom : 5px; 
margin-left : 0px; 
} 
.gjs-lofty-navbar-center-topnav {
position : relative; 
overflow-x : hidden; 
overflow-y : hidden; 
background-color : rgb(41, 42, 46); 
height : 150px; 
} 
.gjs-lofty-navbar-center-topnav p {
float : left; 
text-align : center; 
padding-top : 14px; 
padding-right : 16px; 
padding-bottom : 14px; 
padding-left : 16px; 
text-decoration-line : none; 
text-decoration-thickness : initial; 
text-decoration-style : initial; 
text-decoration-color : initial; 
} 
.gjs-lofty-navbar-center-topnav p.left {
color : white; 
} 
.gjs-lofty-navbar-center-topnav-centered-left {
float : none; 
position : absolute; 
top : 45%; 
transform : translate(-50%, -50%); 
font-family : Inika; 
font-size : 20px; 
} 
.gjs-lofty-navbar-center-topnav-centered-right {
float : none; 
position : absolute; 
top : 45%; 
transform : translate(-50%, -50%); 
font-family : Inika; 
font-size : 20px; 
} 
.gjs-lofty-navbar-center-topnav-right {
float : right; 
position : relative; 
top : 50%; 
font-size : 17px; 
} 
.gjs-lofty-navbar-center-topnav-image {
position : absolute; 
top : 10%; 
} 
#gjs-lofty-navbar-center-menu__toggle:checked + .menu__btn > span {
transform : rotate(45deg); 
left : 120px; 
top : -30px; 
} 
#gjs-lofty-navbar-center-menu__toggle:checked + .menu__btn > span::before {
top : 0px; 
transform : rotate(0deg); 
background-color : black; 
} 
#gjs-lofty-navbar-center-menu__toggle:checked + .menu__btn > span::after {
top : 0px; 
background-color : black; 
transform : rotate(90deg); 
} 
#gjs-lofty-navbar-center-menu__toggle:checked ~ .menu__box {
left : 0px !important; 
} 
.gjs-lofty-navbar-center-menu__btn {
position : absolute; 
top : 45%; 
left : 5%; 
width : 26px; 
height : 26px; 
cursor : pointer; 
z-index : 1; 
} 
.gjs-lofty-navbar-center-menu__btn > span, .gjs-lofty-navbar-center-menu__btn > span::before, .gjs-lofty-navbar-center-menu__btn > span::after {
display : block; 
position : absolute; 
width : 100%; 
height : 2px; 
background-color : rgb(214, 164, 25); 
transition-duration : 0.25s; 
} 
.gjs-lofty-navbar-center-menu__btn > span::before {
content : ""; 
top : -8px; 
} 
.gjs-lofty-navbar-center-menu__btn > span::after {
content : ""; 
top : 8px; 
} 
.gjs-lofty-navbar-center-menu__box {
display : block; 
position : fixed; 
top : 0px; 
left : -100%; 
width : 300px; 
height : 100%; 
margin-top : 0px; 
margin-right : 0px; 
margin-bottom : 0px; 
margin-left : 0px; 
padding-top : 80px; 
padding-right : 0px; 
padding-bottom : 80px; 
padding-left : 0px; 
list-style-position : initial; 
list-style-image : initial; 
list-style-type : none; 
background-color : rgb(214, 164, 25); 
box-shadow : rgba(0, 0, 0, 0.4) 2px 2px 6px; 
transition-duration : 0.25s; 
} 
.gjs-lofty-navbar-center-menu__item {
display : block; 
padding-top : 12px; 
padding-right : 24px; 
padding-bottom : 12px; 
padding-left : 24px; 
text-align : center; 
color : black; 
font-family : Roboto, sans-serif; 
font-size : 20px; 
font-weight : 600; 
text-decoration-line : none; 
text-decoration-thickness : initial; 
text-decoration-style : initial; 
text-decoration-color : initial; 
transition-duration : 0.25s; 
} 
.gjs-lofty-navbar-center-menu__item:hover  {
background-color : rgb(207, 216, 220); 
} 
.gjs-lofty-navbar-center-dropbtn {
background-color : rgb(41, 42, 46); 
color : white; 
padding-top : 12px; 
padding-right : 12px; 
padding-bottom : 12px; 
padding-left : 12px; 
font-size : 14px; 
border-top-width : initial; 
border-right-width : initial; 
border-bottom-width : initial; 
border-left-width : initial; 
border-top-style : none; 
border-right-style : none; 
border-bottom-style : none; 
border-left-style : none; 
border-top-color : initial; 
border-right-color : initial; 
border-bottom-color : initial; 
border-left-color : initial; 
border-image-source : initial; 
border-image-slice : initial; 
border-image-width : initial; 
border-image-outset : initial; 
border-image-repeat : initial; 
cursor : pointer; 
max-width : 100%; 
font-family : Arial, Helvetica, sans-serif; 
} 
.gjs-lofty-navbar-center-dropdown {
position : relative; 
top : 20%; 
} 
.gjs-lofty-navbar-center-dropdown-content {
display : none; 
position : absolute; 
background-color : gray; 
min-width : 60px; 
box-shadow : rgba(0, 0, 0, 0.2) 0px 8px 16px 0px; 
z-index : 1; 
} 
.gjs-lofty-navbar-center-dropdown-content a {
color : white; 
padding-top : 4px; 
padding-right : 6px; 
padding-bottom : 4px; 
padding-left : 6px; 
text-decoration-line : none; 
text-decoration-thickness : initial; 
text-decoration-style : initial; 
text-decoration-color : initial; 
display : block; 
font-family : Arial, Helvetica, sans-serif; 
} 
.gjs-lofty-navbar-center-dropdown-content a:hover {
background-color : rgb(241, 241, 241); 
} 
.gjs-lofty-navbar-center-dropdown:hover .dropdown-content {
display : block; 
} 
.gjs-lofty-navbar-center-dropdown:hover .dropbtn {
background-color : rgb(41, 42, 46); 
} 
#itkxb {
padding : 10px; 
min-height : 100vh; 
display : flex; 
flex-direction : column; 
} 
#igk42 {
padding : 10px; 
min-height : 100px; 
display : flex; 
} 
#i2k9f {
padding : 10px; 
min-height : 100px; 
width : 10%; 
} 
#ir7b67 {
padding : 0 0 0 0; 
min-height : 0; 
width : 50px; 
border : 2px solid rgba(240, 80, 80, 1); 
height : 0; 
} 
#i1giw1 {
padding : 10px; 
min-height : 0; 
width : 60px; 
border : 0 solid black; 
display : flex; 
justify-content : center; 
flex-direction : column; 
} 
#iqqtir {
padding : 10px; 
display : block; 
color : rgba(221, 227, 237, 1); 
font-size : 32px; 
font-weight : 700; 
} 
#i8yjmg {
padding : 10px; 
min-height : 0; 
display : flex; 
float : none; 
width : 100%; 
text-align : right; 
position : relative; 
justify-content : flex-start; 
} 
#iy0kzs {
padding : 10px; 
min-height : 100px; 
display : flex; 
} 
#ici8dx {
padding : 10px; 
min-height : 0; 
} 
#igd98s {
padding : 10px; 
min-height : 100px; 
width : 10%; 
} 
#is2zfi {
padding : 10px; 
min-height : 100px; 
width : 10%; 
} 
.gjs-row {
display : flex; 
justify-content : flex-start; 
align-items : stretch; 
flex-wrap : wrap; 
padding-top : 10px; 
padding-right : 10px; 
padding-bottom : 10px; 
padding-left : 10px; 
} 
.gjs-cell {
min-height : 75px; 
width : 50%; 
} 
#i0r22k {
width : 80%; 
} 
#im9aj8 {
width : 48%; 
display : flex; 
flex-direction : column; 
justify-content : flex-end; 
padding : 10px 25px 10px 25px; 
margin : 0 10px 0 0; 
height : auto; 
__bg-type : img; 
background-image : url('https://assetsprojects.s3.amazonaws.com/eewk3il9j2js22.png'); 
background-repeat : no-repeat; 
background-position : center center; 
background-attachment : scroll; 
background-size : cover; 
min-height : 460px; 
} 
#ib32hv {
width : 25%; 
display : flex; 
flex-direction : column; 
justify-content : flex-start; 
padding : 5px 5px 5px 5px; 
margin : 0 0 0 0; 
align-self : auto; 
align-items : center; 
} 
#iqrg2c {
padding : 10px; 
min-height : 100px; 
display : flex; 
} 
#iybi2m {
padding : 10px; 
min-height : 100px; 
width : 10%; 
} 
#inrd62 {
padding : 10px; 
min-height : 100px; 
width : 80%; 
} 
#i93yy4 {
padding : 10px; 
min-height : 100px; 
width : 10%; 
} 
#iytiv9 {
padding : 10px; 
display : block; 
color : rgba(221, 227, 237, 1); 
font-size : 17px; 
text-align : center; 
font-weight : 700; 
} 
#iz6kkb {
padding : 10px; 
display : block; 
color : rgba(221, 227, 237, 1); 
font-size : 16px; 
text-align : center; 
font-weight : 400; 
border : 2px solid rgba(221, 227, 237, 1); 
} 
#ilkxzh {
padding : 25px 100px 25px 100px; 
min-height : 100px; 
__bg-type : color; 
background-image : linear-gradient(rgba(12, 12, 12, 0.9),rgba(12, 12, 12, 0.9)); 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
text-align : left; 
} 
#ikgcxg {
padding : 10px; 
display : block; 
color : rgba(221, 227, 237, 1); 
font-weight : 300; 
} 
#i3k8 {
background-image : url('https://assetsprojects.s3.amazonaws.com/eewk3il9h8nvkw.png'); 
background-repeat : no-repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : cover; 
} 
#iwtk {
background-image : url('https://assetsprojects.s3.amazonaws.com/eewk3il9h8oh4b.png'); 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
} 
#i6h9 {
background-image : url('https://assetsprojects.s3.amazonaws.com/eewk3il9h8nvkw.png'); 
background-repeat : no-repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : cover; 
} 
#if9wqu {
padding : 10px; 
min-height : 100px; 
__bg-type : img; 
background-image : url('https://assetsprojects.s3.amazonaws.com/eewk3il9h8nvkw.png'); 
background-repeat : no-repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : cover; 
} 
@media only screen and (max-width: 992px) {#ihuh {
flex-direction : column; 
}} 
@media only screen and (max-width: 992px) {#inlc {
width : 100%; 
}} 
@media only screen and (max-width: 992px) {#ici8dx {
display : none; 
}} 
@media only screen and (max-width: 992px) {#idpf {
width : 100%; 
}} 
@media only screen and (max-width: 992px) {#im9aj8 {
width : 100%; 
height : 200px; 
}} 
@media only screen and (max-width: 992px) {#ib32hv {
width : 48%; 
height : auto; 
}} 
@media only screen and (max-width: 992px) {#iytiv9 {
font-size : 32px; 
}} 
@media only screen and (max-width: 992px) {#iz6kkb {
font-size : 14px; 
}} 
#izrqjl {
padding : 10px; 
min-height : 100px; 
display : none; 
} 
@media only screen and (max-width: 480px) {#izrqjl {
display : block; 
}} 
@media only screen and (max-width: 480px) {#idpf {
display : none; 
}} 
@media only screen and (max-width: 480px) {#ihuh {
flex-direction : row; 
}} 
@media only screen and (max-width: 480px) {#id8lda {
padding : 10px; 
display : block; 
color : #fff; 
text-decoration : none; 
}} 
@media only screen and (max-width: 480px) {#i2k9f {
display : none; 
}} 
@media only screen and (max-width: 480px) {#iqqtir {
font-size : 17px; 
text-align : left; 
float : none; 
line-height : 50px; 
}} 
@media only screen and (max-width: 480px) {#i8yjmg {
justify-content : center; 
}} 
@media only screen and (max-width: 480px) {#im9aj8 {
width : 100%; 
margin : 0 0 0 0; 
}} 
@media only screen and (max-width: 480px) {#ib32hv {
width : 100%; 
margin : 10px 0 0 0; 
}} 
@media only screen and (max-width: 480px) {#igd98s {
display : none; 
}} 
@media only screen and (max-width: 480px) {#is2zfi {
display : none; 
}} 
@media only screen and (max-width: 480px) {#i0r22k {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#iybi2m {
display : none; 
}} 
@media only screen and (max-width: 480px) {#i93yy4 {
display : none; 
}} 
@media only screen and (max-width: 480px) {#inrd62 {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#iytiv9 {
font-size : 20px; 
}} 
@media only screen and (max-width: 480px) {#iz6kkb {
font-size : 12px; 
}} 
@media only screen and (max-width: 480px) {#ilkxzh {
background-image : linear-gradient(rgba(0, 0, 0, 0.9),rgba(0, 0, 0, 0.9)); 
__bg-type : color; 
padding : 25px 10px 25px 10px; 
}} 
#i5cbcs {
padding : 10px 30px 10px 30px; 
min-height : 100px; 
__bg-type : color; 
background-image : linear-gradient(rgba(69, 69, 69, 1),rgba(69, 69, 69, 1)); 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
display : flex; 
flex-direction : column; 
} 
#izm6hd {
padding : 10px; 
display : block; 
color : rgba(221, 227, 237, 1); 
font-size : 18px; 
font-weight : 300; 
} 
#ism2ml {
padding : 10px; 
display : block; 
color : rgba(221, 227, 237, 1); 
font-size : 18px; 
font-weight : 700; 
} 
#iteo37 {
padding : 10px; 
display : block; 
color : rgba(221, 227, 237, 1); 
font-size : 18px; 
font-weight : 400; 
} 
#ie3crw {
padding : 10px; 
min-height : 100px; 
width : 100%; 
height : 50%; 
__bg-type : img; 
background-image : url('https://assetsprojects.s3.amazonaws.com/eewk3il9mutgzq.png'); 
background-repeat : no-repeat; 
background-position : center center; 
background-attachment : scroll; 
background-size : cover; 
} 
#iicpbi {
padding : 10px; 
min-height : 100px; 
height : 50%; 
width : 100%; 
display : flex; 
flex-direction : column; 
} 
#igyj4a {
padding : 10px; 
display : block; 
color : rgba(221, 227, 237, 1); 
font-size : 18px; 
font-weight : 300; 
} 
#ifcma2 {
padding : 10px; 
display : block; 
color : rgba(221, 227, 237, 1); 
font-size : 18px; 
font-weight : 700; 
} 
#i60fbk {
padding : 10px; 
display : block; 
color : rgba(221, 227, 237, 1); 
font-size : 18px; 
font-weight : 400; 
} 
#i46q5l {
padding : 10px; 
display : block; 
color : rgba(221, 227, 237, 1); 
font-size : 18px; 
font-weight : 400; 
} 
#i2j4rg {
padding : 10px; 
min-height : 100px; 
width : 100%; 
height : 50%; 
__bg-type : img; 
background-image : url('https://assetsprojects.s3.amazonaws.com/eewk3il9mutv1k.png'); 
background-repeat : no-repeat; 
background-position : center center; 
background-attachment : scroll; 
background-size : cover; 
} 
#ivg7vp {
padding : 10px; 
display : block; 
color : rgba(221, 227, 237, 1); 
font-size : 18px; 
font-weight : 300; 
} 
#io1i0m {
padding : 10px; 
display : block; 
color : rgba(221, 227, 237, 1); 
font-size : 18px; 
font-weight : 700; 
} 
#ij74ak {
padding : 10px; 
display : block; 
color : rgba(221, 227, 237, 1); 
font-size : 18px; 
font-weight : 400; 
} 
#ioor6e {
padding : 10px; 
display : block; 
color : rgba(221, 227, 237, 1); 
font-size : 18px; 
font-weight : 400; 
} 
#itq5v7 {
padding : 10px; 
min-height : 100px; 
height : 50%; 
width : 100%; 
display : flex; 
flex-direction : column; 
} 
#i1npuo {
width : 25%; 
display : flex; 
flex-direction : column; 
justify-content : flex-start; 
padding : 5px 5px 5px 5px; 
margin : 0 10px 0 0; 
align-self : auto; 
align-items : center; 
} 
@media only screen and (max-width: 992px) {#i1npuo {
width : 48%; 
height : auto; 
}} 
@media only screen and (max-width: 480px) {#i1npuo {
width : 100%; 
margin : 10px 0 0 0; 
}} 
#iqz0wy {
padding : 10px; 
min-height : 100px; 
width : 10%; 
} 
@media only screen and (max-width: 480px) {#iqz0wy {
display : none; 
}} 
#ic4tqi {
padding : 10px; 
display : block; 
color : rgba(221, 227, 237, 1); 
font-size : 17px; 
text-align : center; 
font-weight : 700; 
} 
@media only screen and (max-width: 992px) {#ic4tqi {
font-size : 32px; 
}} 
@media only screen and (max-width: 480px) {#ic4tqi {
font-size : 20px; 
}} 
#i4km5p {
padding : 10px; 
display : block; 
color : rgba(221, 227, 237, 1); 
font-size : 16px; 
text-align : center; 
font-weight : 400; 
border : 2px solid rgba(221, 227, 237, 1); 
} 
@media only screen and (max-width: 992px) {#i4km5p {
font-size : 14px; 
}} 
@media only screen and (max-width: 480px) {#i4km5p {
font-size : 12px; 
}} 
#irhufq {
padding : 10px; 
min-height : 100px; 
width : 80%; 
} 
@media only screen and (max-width: 480px) {#irhufq {
width : 100%; 
}} 
#in1i1u {
padding : 10px; 
min-height : 100px; 
width : 10%; 
} 
@media only screen and (max-width: 480px) {#in1i1u {
display : none; 
}} 
#ixjb7g {
padding : 10px; 
min-height : 100px; 
display : flex; 
} 
#ilawjs {
padding : 10px; 
min-height : 100px; 
width : 10%; 
} 
@media only screen and (max-width: 480px) {#ilawjs {
display : none; 
}} 
#iubvki {
padding : 10px; 
display : block; 
color : rgba(221, 227, 237, 1); 
font-size : 18px; 
font-weight : 300; 
} 
#iqk98g {
padding : 10px; 
display : block; 
color : rgba(221, 227, 237, 1); 
font-size : 18px; 
font-weight : 700; 
} 
#i2qbth {
padding : 10px; 
display : block; 
color : rgba(221, 227, 237, 1); 
font-size : 18px; 
font-weight : 400; 
} 
#i86bjo {
padding : 10px 30px 10px 30px; 
min-height : 100px; 
__bg-type : color; 
background-image : linear-gradient(rgba(69, 69, 69, 1),rgba(69, 69, 69, 1)); 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
display : flex; 
flex-direction : column; 
} 
#i225kn {
width : 48%; 
display : flex; 
flex-direction : column; 
justify-content : flex-end; 
padding : 10px 25px 10px 25px; 
margin : 0 10px 0 0; 
height : auto; 
__bg-type : img; 
background-image : url('https://assetsprojects.s3.amazonaws.com/eewk3il9iziu7n.png'); 
background-repeat : no-repeat; 
background-position : center center; 
background-attachment : scroll; 
background-size : cover; 
min-height : 460px; 
} 
@media only screen and (max-width: 992px) {#i225kn {
width : 100%; 
height : 200px; 
}} 
@media only screen and (max-width: 480px) {#i225kn {
width : 100%; 
margin : 0 0 0 0; 
min-height : 100px; 
}} 
#ij78fe {
padding : 10px; 
min-height : 100px; 
width : 100%; 
height : 50%; 
__bg-type : img; 
background-image : url('https://assetsprojects.s3.amazonaws.com/eewk3il9j0z3yn.png'); 
background-repeat : no-repeat; 
background-position : center center; 
background-attachment : scroll; 
background-size : cover; 
} 
#i4hwm3 {
padding : 10px; 
display : block; 
color : rgba(221, 227, 237, 1); 
font-size : 18px; 
font-weight : 300; 
} 
#itn9tz {
padding : 10px; 
display : block; 
color : rgba(221, 227, 237, 1); 
font-size : 18px; 
font-weight : 700; 
} 
#iym76r {
padding : 10px; 
display : block; 
color : rgba(221, 227, 237, 1); 
font-size : 18px; 
font-weight : 400; 
} 
#ivli0j {
padding : 10px; 
display : block; 
color : rgba(221, 227, 237, 1); 
font-size : 18px; 
font-weight : 400; 
} 
#iuq5rd {
padding : 10px; 
min-height : 100px; 
height : 50%; 
width : 100%; 
display : flex; 
flex-direction : column; 
} 
#i4mbhx {
width : 25%; 
display : flex; 
flex-direction : column; 
justify-content : flex-start; 
padding : 5px 5px 5px 5px; 
margin : 0 0 0 0; 
align-self : auto; 
align-items : center; 
} 
@media only screen and (max-width: 992px) {#i4mbhx {
width : 48%; 
height : auto; 
}} 
@media only screen and (max-width: 480px) {#i4mbhx {
width : 100%; 
margin : 10px 0 0 0; 
}} 
#il598c {
padding : 10px; 
display : block; 
color : rgba(221, 227, 237, 1); 
font-size : 18px; 
font-weight : 300; 
} 
#i2cxdh {
padding : 10px; 
display : block; 
color : rgba(221, 227, 237, 1); 
font-size : 18px; 
font-weight : 700; 
} 
#i2oht1 {
padding : 10px; 
display : block; 
color : rgba(221, 227, 237, 1); 
font-size : 18px; 
font-weight : 400; 
} 
#ibx0m7 {
padding : 10px; 
display : block; 
color : rgba(221, 227, 237, 1); 
font-size : 18px; 
font-weight : 400; 
} 
#i8cf3k {
padding : 10px; 
min-height : 100px; 
height : 50%; 
width : 100%; 
display : flex; 
flex-direction : column; 
} 
#i4f58n {
padding : 10px; 
min-height : 100px; 
width : 100%; 
height : 50%; 
__bg-type : img; 
background-image : url('https://assetsprojects.s3.amazonaws.com/eewk3il9j0z3yn.png'); 
background-repeat : no-repeat; 
background-position : center center; 
background-attachment : scroll; 
background-size : cover; 
} 
#itbygo {
width : 25%; 
display : flex; 
flex-direction : column; 
justify-content : flex-start; 
padding : 5px 5px 5px 5px; 
margin : 0 10px 0 0; 
align-self : auto; 
align-items : center; 
} 
@media only screen and (max-width: 992px) {#itbygo {
width : 48%; 
height : auto; 
min-height : 100px; 
}} 
@media only screen and (max-width: 480px) {#itbygo {
width : 100%; 
margin : 10px 0 0 0; 
}} 
#iwc9uq {
width : 80%; 
} 
@media only screen and (max-width: 480px) {#iwc9uq {
width : 100%; 
}} 
#inabor {
padding : 10px; 
min-height : 100px; 
width : 10%; 
} 
@media only screen and (max-width: 480px) {#inabor {
display : none; 
}} 
#i79nhx {
padding : 10px; 
min-height : 100px; 
display : flex; 
} 
@media only screen and (max-width: 992px) {#i79nhx {
min-height : 100vh; 
}} 
@media only screen and (max-width: 992px) {#iuq5rd {
height : 250px; 
}} 
@media only screen and (max-width: 992px) {#i8cf3k {
height : 250px; 
}} 
@media only screen and (max-width: 992px) {#ij78fe {
height : 250px; 
}} 
@media only screen and (max-width: 992px) {#iybi2m {
width : 5%; 
}} 
@media only screen and (max-width: 992px) {#i93yy4 {
width : 5%; 
}} 
@media only screen and (max-width: 992px) {#inrd62 {
width : 90%; 
}} 
@media only screen and (max-width: 992px) {#iqz0wy {
width : 5%; 
}} 
@media only screen and (max-width: 992px) {#in1i1u {
width : 5%; 
}} 
@media only screen and (max-width: 992px) {#irhufq {
width : 90%; 
}} 
@media only screen and (max-width: 992px) {#iy0kzs {
min-height : 100vh; 
}} 
@media only screen and (max-width: 992px) {#iicpbi {
height : 250px; 
}} 
@media only screen and (max-width: 992px) {#ie3crw {
height : 250px; 
}} 
@media only screen and (max-width: 480px) {#i86bjo {
padding : 10px 10px 10px 10px; 
}} 
@media only screen and (max-width: 480px) {#iubvki {
font-size : 14px; 
}} 
@media only screen and (max-width: 480px) {#iqk98g {
font-size : 14px; 
}} 
@media only screen and (max-width: 480px) {#i2qbth {
font-size : 14px; 
}} 

  
  
}
  